.modal {
	display: block !important;
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	z-index: 99999;

	overflow-y: auto;
	visibility: hidden;
	opacity: 0;

	transition:
		visibility 300ms ease-out ,
		opacity 300ms ease-out ;

	// .modal-dialog
	&-dialog {
		position: relative;
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		margin-top: 20vh;
		display: block;
		background-color: #fff;
		height: auto;
		border-radius: .5em;
		box-shadow: 0 0 1em 1em rgba(#000,.15);
		opacity: 0;
		transform: translateY(-2em);
		transition:
			transform 300ms ease-in-out 100ms,
			opacity 300ms ease-in-out 100ms;
	}

	// .modal-close
	&-close {
		position: absolute;
		top: 1em;
		right: 1em;
		border: none;
		background-color: transparent;
		font-size: 1.25em;
		color: $darkgray;
		cursor: pointer;

		&:hover {
			color: darken( $darkgray , 10% );
		}
	}


}

.modal.show {
	visibility: visible;
	opacity: 1;

	.modal-dialog {
		opacity: 1;
		transform: translateY(0);
	}
}


body {
	&:before {
		content: '';
		@extend %fixedFill;
		z-index: 9999;
		background-color: rgba(#000,.85);
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 300ms ease-in-out ,
			visibility 300ms ease-in-out ;
	}

	&.modal-open {
		overflow-y: hidden;
		&:before {
			opacity: 1;
			visibility: visible;
		}
	}
}

