.dialog {
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem;
  z-index: 99999;
  @include flex(center, center);
  width: 100vw;
  height: 100vh;

  display: none;

  perspective: 5000px;
  perspective-origin: top center;

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 1;

    background-color: rgba(0, 0, 0, 0.85);
  }

  &.show {
    display: flex;
    perspective-origin: top center;

    &:before {
      animation: animation__dialog__show 0.3s ease-in-out both 1;
    }

    .dialog__container {
      transform-origin: top center;
      animation: animation__dialog__container__show 0.3s ease-in-out both 1 0.5s;
    }
  }

  &.exit {
    display: flex;
    perspective-origin: bottom center;

    &:before {
      animation: animation__dialog__exit 0.3s ease-in-out both 1 0.5s;
    }

    .dialog__container {
      transform-origin: bottom center;
      animation: animation__dialog__container__exit 0.3s ease-in-out both 1;
    }
  }

  // .dialog__container
  &__container {
    width: calc(50vw - 4rem);
    height: calc(50vh - 4rem);
    z-index: 2;

    @include flex(flex-start, flex-start);
    flex-direction: column;

    @media #{$responsive-tablet} {
      width: calc(100vw - 4rem);
      height: calc(100vh - 4rem);
    }
  }

  // .dialog__header
  &__header {
    width: 100%;
    padding: 0;

    @include flex(flex-start, stretch);

    border-bottom: 4px solid $primary-color;

    h4 {
      box-sizing: border-box;
      flex-grow: 1;
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      line-height: 1.4;
      font-weight: bold;
      color: #fff;

      @include flex(flex-start, stretch);

      span {
        width: auto;
        position: relative;
        padding: 0.5rem 2rem;

        letter-spacing: -0.1rem;

        @include flex(flex-start, center);
        background-color: $primary-color;
        color: #fff;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 5px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $primary-color;
        }
      }
    }
  }

  // .dialog__close
  &__close {
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    @include flex(center, center);
    border-radius: 0;
    background-color: transparent;
    transition: 0.15s ease-in-out;

    outline: none;
    text-decoration: none;

    cursor: pointer;

    i,
    .m-icon {
      font-size: 1.2rem;
      color: $red;
      transition: 0.15s ease-in-out;
    }

    &:hover {
      background-color: $red;

      i,
      .m-icon {
        color: #fff;
      }
    }
  }

  // .dialog__body
  &__body {
    flex-grow: 1;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: #fff;

    @include applyScrollbar();

    p, ul, span {
      color: #333 !important;
    }
  }

  // .dialog__wrapper
  &__wrapper {
    width: 100%;
    height: auto;
    padding: 1rem;

    .list {
      columns: unset;
    }
  }
}

@keyframes animation__dialog__show {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes animation__dialog__exit {
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes animation__dialog__container__show {
  0% {
    opacity: 0;
    transform: translateY(-10%) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) rotateX(0deg);
  }
}

@keyframes animation__dialog__container__exit {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
