@import "../utils/utils.scss";

$fullpage_bg: transparent;
$subsection_menu_textcolor: $white;
$subsection_menu_textcolor_active: $white;
$subsection_menu_bgcolor: transparent;
$subsection_menu_bgcolor_active: $primary-color;
$subsection_menmu_highlight: $color-dark;
$subsection_menmu_highlight_active: $primary-color;

body {
	margin: 0;
	padding: 0;
	display: block;
	overflow: hidden;
	width: 100vw;
	height: 100vh;

	font-family: $font-family;
	background-color: $color-dark;

	&.hide--sidebar {
		.fullpageMenu {
			right: -250px;
			@media #{$responsive-phone} {
				right: auto;
				bottom: -80px;
			}
		}
		.navBurger {
			top: 40px;
			right: 120px;
			transition: 0.5s ease-in-out 1s;

			&:before, &:after {
				right: 140px;
			}
			&:before {
				top: 55px;
				bottom: calc(100% - 55px);
			}
			&:after {
				top: 105px;
				bottom: calc(100% - 105px);
			}

			@media #{$responsive-phone} {
				right: 10px;
				&:before, &:after {
					right: 30px;
				}
			}
		}
		.branding {
			@media #{$responsive-laptop} {
				min-height: 80px;
			}
			.branding__logo {
				opacity: 1;
				transition: 0.15s ease-in-out;
				@media #{$responsive-laptop} {
					max-width: 160px;
				}
			}
		}
	}

	&.show--sections {
		.branding {

			@media #{$responsive-laptop} {
				min-height: 80px;
			}

			.branding__logo {
				opacity: 1;
				max-width: 220px;
				position: relative;
				top: 0%;
				margin-top: 0;
				left: 0%;
				margin-left: 0;
				animation: brandingIntro__small 0.5s ease-in-out both 1 0s;
				@media #{$responsive-laptop} {
					max-width: 160px;
				}
			}
		}

		#vidbg {
			animation: hideVidBG 0.1s linear both 1 1s;
		}
	}

	&.show--hero {
		.call-to-action {
			.jumpButton {
				margin-top: -60px;
				transition: margin 1s ease-in-out 3s;
			}
			.label {
				transition: opacity 1s ease-in-out 3s, margin 1s ease-in-out 3s, color 1s ease-in-out 0s;
				&:nth-child(1) {
					opacity: 1;
					margin-right: 90px;
					color: rgba($white, 0.4);
				}
				&:nth-child(3) {
					opacity: 1;
					margin-left: 90px;
					color: rgba($white, 0.4);
				}
			}
			&:hover {
				.label {
					transition: opacity 1s ease-in-out 3s, margin 1s ease-in-out 3s, color 1s ease-in-out 0s;
					&:nth-child(1), &:nth-child(3) {
						color: rgba($white, 0.9);
					}
				}
			}
		}

		.hero__context {
			&:before {
				animation: titileRibbonAnimation 0.5s ease-in-out both 1 0.5s;
			}
		}
		.hero__subtitle {
			opacity: 1;
			margin-left: 0;
			transition: 0.5s ease-in-out 4.5s;
		}

		.branding {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 11000;
			width: 100%;
			min-height: auto;
			// height: 100%;
			// padding: 2rem 0 0 0;

			// animation: brandingIntro 0s ease-in-out both 1 4s;

			@media #{$responsive-laptop} {
				min-height: 80px;
			}

			&:before {
				opacity: 0;
				animation: brandingIntro__shadow 1s ease-in-out both 1 4s;
			}
			.branding__logo {
				opacity: 1;
				max-width: 220px;
				position: relative;
				top: 0%;
				margin-top: 0;
				left: 0%;
				margin-left: 0;

				transition: opacity 0.5s ease-in-out;
				// animation: brandingIntro__logo 3s ease-in-out both 1 1s;
				@media #{$responsive-laptop} {
					max-width: 160px;
				}
			}
		}

		#vidbg {
			display: block;
		}
	}

	&.show--nav {
		.fullpageMenu {
			z-index: 12000;
			transition: 0.5s ease-in-out, z-index 0s linear 0s;
		}

		&.show--hero {
			.navMenu {
				right: -130px;
				transition: 0.5s ease-in-out 0s;
			}
		}

		.navMenu {
			right: -110px;
			transition: 0.5s ease-in-out 0s;

			ul {
				opacity: 1;
				margin-left: 0rem;
				transition: 0.5s ease-in-out 1s;

				li {
					a {
						span {
							opacity: 1;
							padding-right: 0;
						}
					}

					@for $i from 1 through 10 {
						&:nth-child(#{$i}) {
							a span {
								$navMenu_li_delay: $i * 0.3;
								$navMenu_li_delay: $navMenu_li_delay + 1;
								transition: opacity 0.5s ease-in-out #{$navMenu_li_delay}s, padding 0.5s ease-in-out #{$navMenu_li_delay}s, color 0.15s ease-in-out 0s;
							}

							&:hover {
								> a {
									color: $primary-color;
									transition: 0.15s ease-in-out 0s;
								}
							}
						}
					}
				}
			}
		}

		.navBurger {
			top: 60px;
			transition: 0.5s ease-in-out 0.5s;

			@media #{$responsive-phone} {
				top: 20px;
			}

			&:before, &:after {
				transition: 0.5s ease-in-out 0.5s;
			}

			&:before {
				top: 0px;
				bottom: calc(100% - 55px);
			}
			&:after {
				top: 105px;
				bottom: 0;
			}
		}
	}

	&.show--sidebar {
		// .navBurger {
		//  right: -160px;
		//  transition: 0.15s ease-in-out 0.1s;
		//  &:before, &:after {
		//    right: -160px;
		//    transition: 0.15s ease-in-out 0.1s;
		//  }
		// }

		.fullpageMenu {
			right: 0;
			transition: 0.5s ease-in-out, z-index 0s linear 0s;
			ul {
				li:not(.active) {
					&:before {
						background-color: rgba($color-border, 0);
					}
					&:hover {
						&:before {
							background-color: rgba($color-border, 0.7);
						}
					}
				}

				li {
					a {
						span.prefix:before {
							opacity: 0;
							transition: 0.15s ease-in-out 0s;
						}
					}
				}
			}
		}
	}
}
@keyframes hideVidBG {
	0% {
		display: block;
	}
	100% {
		display: none;
	}
}
@keyframes brandingIntro__small {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes brandingIntro {
	0% {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 15000;
		width: auto;
		min-height: auto;
		height: 100%;
		padding: 0;
	}
	100% {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 11000;
		width: 100%;
		min-height: 120px;
		height: auto;
		padding: 2rem 300px 0 6.4rem;

		@media #{$responsive-laptop} {
			width: auto;
			right: 300px;
		}
	}
}
@keyframes brandingIntro__shadow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes brandingIntro__logo {
	0% {
		opacity: 0;
		max-width: 400px;
		position: relative;
		top: 50%;
		margin-top: -72px;
		left: 50%;
		margin-left: -200px;
	}
	30% {
		opacity: 1;
		max-width: 600px;
		position: relative;
		top: 50%;
		margin-top: -108px;
		left: 50%;
		margin-left: -300px;
	}
	60% {
		opacity: 1;
		max-width: 600px;
		position: relative;
		top: 50%;
		margin-top: -108px;
		left: 50%;
		margin-left: -300px;
	}
	80% {
		opacity: 0;
		max-width: 400px;
		position: relative;
		top: 50%;
		margin-top: -72px;
		left: 50%;
		margin-left: -200px;
	}
	81% {
		opacity: 0;
		max-width: 220px;
		position: relative;
		top: 0%;
		margin-top: 0;
		left: 0;
		margin-left: 50px;
	}
	100% {
		opacity: 1;
		max-width: 220px;
		position: relative;
		top: 0%;
		margin-top: 0;
		left: 0%;
		margin-left: 0;
	}
}

.app {
	width: 100vw;
	height: 100vh;

	// &:before {
	//  content: '';
	//  display: block;
	//  width: 100vw;
	//  height: 100vh;
	//  position: fixed;
	//  top: 0;
	//  left: 0;
	//  pointer-events: none;
	//  background-image: url('../images/screen_overlay.png');
	//  z-index: 100000;
	// }
}

.preloader {
	background-image: url('../images/preloader_bg.png');
}

// BRANDING
.branding {
	position: fixed;
	top: 0;
	left: 0;
	right: 300px;
	z-index: 11000;
	width: 100%;
	min-height: 120px;
	padding: 2rem 6.4rem 0 6.4rem !important;

	@media #{$responsive-laptop} {
		padding-top: 1rem !important;
		min-height: 80px;
	}

	@media #{$responsive-tablet} {
		padding: 2rem 1rem 0 1rem !important;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

		transition: 0.15s ease-in-out 0s;
	}

	.branding__logo {
		display: block;

		width: 100%;
		max-width: 220px;

		text-decoration: none;

		position: relative;
		z-index: 2;

		img {
			width: 100%;
			height: auto;
		}

		@media #{$responsive-laptop} {
			max-width: 160px;
		}
	}
}

// NAVIGATION
.nav {
	&.show {
		.navBurger {
			a {
				span {
					&:nth-child(1) {
						transition: 0.15s ease-in-out 0s;
						transform: translateY(0px) rotate(-45deg);
						background-color: $white;
					}
					&:nth-child(2) {
						transition: 0.15s ease-in-out 0s;
						transform: translateY(0px) scale(0);
						background-color: $white;
					}
					&:nth-child(3) {
						transition: 0.15s ease-in-out 0s;
						transform: translateY(0px) rotate(45deg);
						background-color: $white;
					}
				}
			}
		}
		.navMenu {
			// right: 0;
		}
	}
}
.navBurger {
	position: fixed;
	z-index: 12300;
	top: 60px;
	// right: 250px;
	right: 140px;
	transition: 0.5s ease-in-out 0s;

	@media #{$responsive-tablet} {
		right: 1rem;
	}

	@media #{$responsive-phone} {
		top: 20px;
	}

	&:before, &:after {
		content: '';
		display: block;
		width: 1px;
		background-color: rgba($white, 0.4);
		position: fixed;
		// right: 270px;
		right: 160px;
		transition: 1s ease-in-out 0s;

		@media #{$responsive-tablet} {
			right: 1.2rem;
		}
	}

	&:before {
		top: 0px;
		bottom: calc(100% - 55px);
		@media #{$responsive-phone} {
			display: none;
		}
	}
	&:after {
		top: 105px;
		bottom: calc(100% - 125px);

		@media #{$responsive-phone} {
			display: none;
		}
	}

	a {
		@include flex(center, center, row);
		width: 40px;
		height: 40px;
		position: relative;
		span {
			display: block;
			width: 40px;
			height: 4px;
			background-color: $white;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			left: 0;

			transition: 0.15s ease-in-out 0s;

			&:nth-child(1) {
				transform: translateY(-8px);
			}
			&:nth-child(2) {
				transform: translateY(0px);
			}
			&:nth-child(3) {
				transform: translateY(8px);
			}
		}

		&:hover {
			span {
				&:nth-child(1) {
					animation: burgerHover 0.3s ease-in-out both 1 0s;
				}
				&:nth-child(2) {
					animation: burgerHover 0.3s ease-in-out both 1 0.15s;
				}
				&:nth-child(3) {
					animation: burgerHover 0.3s ease-in-out both 1 0.3s;
				}
			}
		}
	}
}
.navMenu {
	position: fixed;
	z-index: 12200;
	top: 0;
	right: -100%;
	width: 100%;
	max-width: 620px;
	height: 100vh;
	background-color: rgba($color-black, 0.95);

	transition: 0.5s ease-in-out 1s;

	ul {
		@include flex(flex-start, flex-end, column);
		padding: 0;
		padding-right: 290px;
		padding-top: 100px;
		margin: 0;
		list-style: none;

		opacity: 0;
		margin-left: 2rem;

		transition: 0.5s ease-in-out 0s;

		@media #{$responsive-tablet} {
			padding-right: 170px;
		}

		li {
			@include flex(flex-end, center);
			margin: 0;
			padding: 0;
			text-align: right;

			a {
				display: block;
				text-decoration: none;
				transition: 0.15s ease-in-out 0s;

				span {
					display: block;
					padding: 0.2rem 0;
					padding-right: 1rem;
					opacity: 0;
					transition: 0.15s ease-in-out 0s;
				}
			}

			&:hover {
				> a {
					color: $primary-color;
					transition: 0.15s ease-in-out 0s;
				}
			}
		}

		> li > a {
			color: $white;
			font-size: 1.8rem;
		}

		li.social {
			padding-top: 1rem;
			padding-bottom: 1rem;
			@include flex(flex-start, flex-end, column);

			h4 {
				text-align: right;
				font-size: 1rem;
				font-weight: 600;
				color: rgba($white, 0.4);
			}
			.social__group {
			}
			.social__item {
				a {
					color: $white;
					font-size: 1rem;
					font-weight: 100;
					transition: 0.15s ease-in-out 0s;
				}
				&:hover {
					a {
						color: $primary-color;
					}
				}
			}
		}
	}
}
@keyframes burgerHover {
	0% {
		background-color: $white;
	}
	5% {
		background-color: $primary-color;
	}
	10% {
		background-color: $white;
	}
	15% {
		background-color: $primary-color;
	}
	20% {
		background-color: $white;
	}
	100% {
		background-color: $primary-color;
	}
}


#vidbg {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	object-position: center center;
	min-width: 100vw;
	min-height: 100vh;
}


// HERO
.hero {
	width: 100vw;
	height: 100vh;
	background-color: $fullpage_bg;
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	color: $white;
	opacity: 1;
	transition: 0.15s ease-in-out;

	@include flex(flex-start, flex-end, row);

	@media #{$responsive-phone} {
		@include flex(flex-start, center, row);
	}

	// background-image: url('../images/Showready-00.png');
	// background-position: center center;
	// background-size: cover;

	&.bury {
		z-index: 2;
	}

	&.rendered {
		z-index: 4;
	}
}
.hero__wrapper {
	@include flex(center, center, column);
	width: 100%;
	margin-bottom: 4rem;

	@media #{$responsive-phone} {
		padding-bottom: 0rem;
		margin-bottom: 0rem;
	}
}
.hero__context {
	padding-left: 8rem;
	position: relative;
	width: 100%;
	margin-bottom: 4rem;

	@media #{$responsive-phone} {
		padding-left: 2rem;
		margin-bottom: 0rem;
	}

	h1, h2 {
		line-height: 1.1;
		text-transform: uppercase;
		span {
			display: block;
		}
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: $primary-color;
	}
}

.hero__title {
	font-size: 3rem;
	line-height: 1.4;
	color: rgba($white, 1);
	font-weight: 600;

	@media #{$responsive-phone} {
		font-size: 2rem;
	}

	span {
		line-height: 1.4;
	}

	.title {
		opacity: 0;
		&.hide {
			display: none;
		}
	}
	.shuffle {
		min-height: 52px;

		@media #{$responsive-phone} {
			min-height: 44px;
		}
	}
}
.hero__subtitle {
	font-size: 1.6rem;
	color: rgba($white, 0.5);
	font-weight: 100;
	opacity: 0;

	transition: 0.5s ease-in-out 0s;
	margin-left: 2rem;
	
	@media #{$responsive-phone} {
		font-size: 1.4rem;
	}
}
.call-to-action {
	@include flex(center, center);
	flex-wrap: nowrap;
	width: 100%;
	position: relative;
	min-height: 120px;
		
	user-drag: none; 
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	.jumpButton {
		display: block;
		position: absolute;
		z-index: 2;
		top: 50%;
		margin-top: 300px;
		left: 50%;
		margin-left: -60px;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		padding: 0;
		border: 4px solid #2a2a2a;
		// background-color: $primary-color;
		// box-shadow: 0 0 10px 0px rgba($primary-color, 0.5);

		cursor: pointer;

		transition: margin 1s ease-in-out 0s;

		&:hover {
			span.light {
				animation: jumpButtonPulse 3s ease-in-out both infinite 0s;
			}
		}

		&.pressed {
			span.start {
				transform: scale(0.9);
			}
		}

		span {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: 0.15s ease-in-out;

			// background-position: center center;
			// background-repeat: no-repeat;
			// background-size: cover;

			img {
				width: 100%;
				height: 100%;
				line-height: 0;
				display: block;
			}

			&.light {
				z-index: 10;
				background-color: $primary-color;
				top: 50%;
				left: 50%;
				margin-top: -56px;
				margin-left: -56px;
				width: 112px;
				height: 112px;
				border-radius: 50%;
			}
			&.lines {
				z-index: 20;
			}
			&.start {
				z-index: 30;
			}
			&.glow {
				display: block;
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				margin-top: -56px;
				margin-left: -56px;
				width: 112px;
				height: 112px;
				border-radius: 50%;
				background-color: $primary-color;
				animation: jumpButtonBreathe 3s ease-in-out both infinite 0s;
			}
		}
	}
	.label {
		font-size: 10px;
		font-weight: 500;
		line-height: 1.1;
		color: rgba($white, 0.2);
		text-transform: uppercase;
		position: absolute;
		opacity: 0;

		transition: opacity 1s ease-in-out 0s, margin 1s ease-in-out 0s, color 1s ease-in-out 0s;

		&:nth-child(1) {
			text-align: right;
			right: 50%;
			margin-right: 120px;
		}
		&:nth-child(3) {
			text-align: left;
			left: 50%;
			margin-left: 120px;
		}
	}

	&:hover {
		.label {
			transition: opacity 1s ease-in-out 0s, margin 1s ease-in-out 0s, color 1s ease-in-out 0s;
			color: rgba($white, 0.9);
		}
	}
}
@keyframes jumpButtonBreathe {
	0%, 100% {
		box-shadow: 0 0 10px 0px rgba($primary-color, 0.5);
	}
	50% {
		box-shadow: 0 0 100px 10px rgba($primary-color, 0.9);
	}
}
@keyframes jumpButtonPulse {
	0%, 20%, 40%, 60%, 80%, 100% {
		background-color: $primary-color;
	}
	10%, 30%, 50%, 70%, 90% {
		background-color: $color-green;
	}
}

// FULLPAGE
.fullpageMenu {
	position: fixed;
	z-index: 12400;
	top: 0;
	right: -222px;
	width: 100%;
	max-width: 230px;
	height: 100vh;
	transition: 0.5s ease-in-out, z-index 0s linear 1s;
	background-color: $color-black;
	
	@media #{$responsive-phone} {
		@include flex(space-between, flex-end);
		height: auto;
		width: 100vw;
		max-width: 100vw;

		top: auto;
		right: auto;
		left: 0;
		bottom: 0;
	}

	ul {
		@include flex(space-between, flex-end, column);
		height: 100%;
		width: 100%;

		margin: 0;
		padding: 0;
		list-style: none;
		
		@media #{$responsive-phone} {
			@include flex(space-between, flex-end);
			height: 60px;
			width: 100vw;
		}

		li {
			@include flex(flex-start, stretch, row);
			width: 100%;
			background-color: rgba($color-black, 0.75);
			position: relative;
			flex-grow: 1;
			height: 100%;
			background-position: center center;
			background-size: cover;
			overflow: hidden;

			&:not(:last-child) {
				margin-bottom: 4px;
				@media #{$responsive-phone} {
					margin-bottom: 0px;
				}
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: 8px;
				height: 100%;
				background-color: rgba($color-border, 0.3);
				transition: 0.15s ease-in-out;

				@media #{$responsive-phone} {
					width: 100%;
					height: 4px;
					background-color: rgba($color-border, 0);
				}
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color-black, 0.5);
				transition: 0.5s ease-in-out;
			}

			&.active {
				&:before {
					background-color: rgba($primary-color, 1);
				}
				a {
					span.label {
						color: $primary-color;
					}
					span.prefix {

						@media #{$responsive-phone} {
							color: $primary-color;
						}

						&:before {
							opacity: 1;
							transition: 0.15s ease-in-out 0s;
						}
					}
				}

				&:after {
					background-color: rgba($color-black, 0.8);
					@media #{$responsive-phone} {
						background-color: rgba($color-black, 0.95);
					}
				}
			}

			a {
				@include flex(center, flex-start, column);
				width: 100%;
				padding-left: 20px;
				text-decoration: none;
				position: relative;
				z-index: 10;

				@media #{$responsive-phone} {
					@include flex(flex-start, center, column);
					padding-left: 0px;
					padding-top: 0.85rem;
				}

				span {
					display: block;
					font-size: 1.4rem;
					line-height: 1.1;
					color: $white;
					@media #{$responsive-laptop} {
						font-size: 1.2rem;
					}
					@media #{$responsive-phone} {
						font-size: 0.8rem;
					}
					&.prefix {
						@media #{$responsive-phone} {
							font-weight: bold;
							margin-bottom: 0.5rem;
						}
						&:before {
							content: attr(data-index);
							display: block;
							position: absolute;
							left: -30px;
							top: 50%;
							margin-top: -9px;
							text-align: right;
							opacity: 0;
							font-size: 0.7rem;
							line-height: 18px;
							transition: 0.15s ease-in-out 0.15s;
							pointer-events: none;

							@media #{$responsive-phone} {
								display: none;
							}
						}
					}
					&.label {
						font-weight: 500;

						@media #{$responsive-phone} {
							font-size: 0.5rem;
						}
					}
				}
			}

			&.home {
				display: none;
			}
			&.profile {
				background-image: url('../images/bg/bg_profile-min.jpg');
			}
			&.experience {
				background-image: url('../images/bg/bg_experience-min.jpg');
			}
			&.skills {
				background-image: url('../images/bg/bg_skillset-min.jpg');
			}
			&.portfolio {
				background-image: url('../images/bg/bg_portfolio-min.jpg');
			}
			&.referral {
				background-image: url('../images/bg/bg_referral-min.jpg');
			}
			&.contact {
				background-image: url('../images/bg/bg_contact-min.jpg');
			}
		}
	}

	// &:hover {
	// }

}
.fullpage {
	font-size: 16px;
	width: 100vw;
	height: 100vh;
	background-color: $fullpage_bg;
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	color: $white;
}


// SECTIONS
.section {
	color: #fff;
	background-color: $fullpage_bg;
	background-position: center center;
	background-size: cover;

	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color-black, 0);
		transition: 1s ease-out;
	}

	&.section-home {
		background-color: transparent !important;
		.section__title {
			display: none;
		}
	}
	&.section-profile {
		background-image: url('../images/bg/bg_profile-min.jpg');
	}
	&.section-experience {
		background-image: url('../images/bg/bg_experience-min.jpg');
	}
	&.section-skills {
		background-image: url('../images/bg/bg_skillset-min.jpg');
	}
	&.section-portfolio {
		background-image: url('../images/bg/bg_portfolio-min.jpg');
	}
	&.section-referral {
		background-image: url('../images/bg/bg_referral-min.jpg');
	}
	&.section-contact {
		background-image: url('../images/bg/bg_contact-min.jpg');
	}

	&.rendered {
		&:before {
			background-color: rgba($color-black, 0.8);
		}
		&.section-home {
			&:before {
				transition: 1s ease-out 3s;
			}
		}
		.section__content, .subsections {
			// opacity: 1;
			// transition: 1s ease-out 1.5s;
		}

		.section__title {
			opacity: 1;
			
			&:before {
				animation: titileRibbonAnimation 1s ease-in-out both 1 0s;
			}
			h1 {
				span {
					&.prefix {
						opacity: 0;
						animation: sectionTitlePrefixRender 1s ease-in-out both 1 1s;
					}
				}
			}
		}

		.subsection__container {
			opacity: 1;
			transition: 1s ease-out 1.5s;
		}

		.subsection__menu {
			opacity: 1;
			top: 0;
			transition: 1s ease-out 1.5s;

			@media #{$responsive-laptop} {
				top: 50px;
			}
		}


		.xslideshow__paging {

			&:before {
				height: 0vh;
				transition: 1s ease-in-out 2s;
			}
		}
		.xslideshow__pagingList {
			opacity: 0;
			// top: 4rem;
			top: calc(125px + 4rem);
			transition: 1s ease-in-out 2s;

			@media #{$responsive-laptop} {
				top: 7rem;
			}
		}
		.xslideshow__closeButton {
			opacity: 0;
			// right: 130px;
			right: -30px;
			transition: 0.3s ease-in-out 2s;

			@media #{$responsive-tablet} {
				right: 0.3rem;
			}
		}
	}

	&.show--subsections {
		.xslideshow {
			animation: hideXslideshow 0.5s ease-in-out both 1 0s;
		}
		.subsections {
			animation: showSubsection 0.5s ease-in-out both 1 0.5s;
		}
		.xslideshow__paging {
			&:before {
				height: 0vh;
				transition: 1s ease-in-out 0s;
			}
		}
		.xslideshow__pagingList {
			opacity: 0;
			top: 4rem;
			transition: 1s ease-in-out 0s;

			@media #{$responsive-laptop} {
				top: 7rem;
			}
		}
		.xslideshow__closeButton {
			opacity: 0;
			// right: 130px;
			right: -30px;
			transition: 0.3s ease-in-out 0s;

			@media #{$responsive-laptop} {
				top: 2.7rem;
			}

			@media #{$responsive-tablet} {
				right: 0.3rem;
			}
		}

		.subsection__container {
			opacity: 1;
			transition: 1s ease-in-out 0s;
		}
		.subsection__menu {
			opacity: 1;
			// top: 140px;
			top: -175px;
			transition: 1s ease-in-out 0s;

			@media #{$responsive-laptop} {
				top: -120px;
			}
		}
	}

	&.show--xslider {
		.xslideshow {
			animation: showXslideshow 0.5s ease-in-out both 1 0.5s;
		}
		.subsections {
			animation: hideSubsection 0.5s ease-in-out both 1 0s;
		}
		.xslideshow__paging {
			&:before {
				height: calc(100vh - 125px);
				transition: 1s ease-in-out 0s;
			}
		}
		.xslideshow__pagingList {
			opacity: 1;
			// top: 2rem;
			top: calc(125px + 4rem);
			transition: 1s ease-in-out 2s;

			@media #{$responsive-laptop} {
				top: calc(125px + 4rem);
			}
		}
		.xslideshow__closeButton {
			opacity: 1;
			right: calc(12rem - 46px);
			// right: 255px;
			transition: 0.3s ease-in-out 0s;

			@media #{$responsive-laptop} {
				top: 125px;
			}
			@media #{$responsive-tablet} {
				right: 0.3rem;
			}
		}

		.subsection__container {
			opacity: 0;
			transition: 1s ease-in-out 0s;
		}
		.subsection__menu {
			opacity: 0;
			top: 0rem;
			transition: 1s ease-in-out 0s;

			@media #{$responsive-laptop} {
				top: 0px;
			}
		}

		.section__title {
			padding-top: 1rem;
			padding-left: 1rem;
			// min-height: 0px;
			&:before {
				animation: titileRibbonAnimation2 0.3s ease-in-out both 1 1s
			}
			h1 {
				span {
					&.prefix {
						
					}
					&.shuffle {
						// opacity: 0;
						animation: titleSuffleHide 0.15s ease-in-out both 1 0s
					}
				}
			}
		}
	}

	&.exit--transition {
		.section__content {
			opacity: 0;
			transition: none;
		}
	}
}
@keyframes titleSuffleHide {
	0% {
		opacity: 1;
		display: block;
	}
	99% {
		opacity: 0;
		display: block;
	}
	100% {
		opacity: 0;
		display: none;
	}
}
@keyframes hideXslideshow {
	0% {
		visibility: visible;
		opacity: 1;
		overflow: visible;
		height: calc(100vh - 400px);
	}
	99% {
		visibility: visible;
		opacity: 0;
		overflow: visible;
		height: calc(100vh - 400px);
	}
	100% {
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		height: 0;
	}
}
@keyframes showSubsection {
	0% {
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		height: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
		overflow: visible;
		height: auto;
	}
	100% {
		visibility: visible;
		opacity: 1;
		overflow: visible;
		height: auto;
	}
}
@keyframes showXslideshow {
	0% {
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		height: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
		overflow: visible;
		// height: calc(100vh - 400px);
		height: 100vh;
	}
	100% {
		visibility: visible;
		opacity: 1;
		overflow: visible;
		// height: calc(100vh - 400px);
		height: 100vh;
	}
}
@keyframes hideSubsection {
	0% {
		visibility: visible;
		opacity: 1;
		overflow: visible;
		height: auto;
	}
	99% {
		visibility: visible;
		opacity: 0;
		overflow: visible;
		height: auto;
	}
	100% {
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		height: 0;
	}
}
.section__wrapper {
	// padding-top: 80px;
	position: absolute;
	width: 100%;
	height: auto;
	// top: 140px;
	top: 0;

	@media #{$responsive-laptop} {
		// top: 85px;
		top: 20px;
	}
}
.section__title {

	@include flex(flex-start, flex-start);
	position: relative;
	padding-left: 8rem;

	margin-bottom: 2rem;
	opacity: 0;
	transition: 0.15s ease-in-out;

	min-height: 140px;
	top: 140px;
	z-index: 11;
	width: calc(100% - 480px);

	@media #{$responsive-laptop} {
		top: 100px;
		margin-bottom: 1rem;
		min-height: 100px;
	}

	@media #{$responsive-tablet} {
		padding-left: 2rem;
		width: calc(100% - 20px);

		pointer-events: none;
	}

	@media #{$responsive-phone} {
		margin-bottom: 0;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: $primary-color;
		z-index: 10;
		transition: 0.15s ease-in-out 0s;
		transition: 0.5s ease-in-out;

		background-image: url('../images/munky_logo.png');
		background-size: 500px;
		background-position: 200% center;
		background-repeat: no-repeat;

		transform-origin: top center;
	}

	h1 {
		font-size: 3rem;
		font-weight: 500;
		line-height: 1.1;
		text-transform: uppercase;
		@include flex(flex-start, flex-start, column);
		position: relative;
		z-index: 12;
		margin: 0;

		@media #{$responsive-laptop} {
			font-size: 2rem;
		}

		span {
			width: auto;
			transition: 0.15s ease-in-out;
			&.prefix {
				opacity: 0;
				margin-right: 1rem;
				font-size: 1.2rem;
				font-weight: 100;
			}
			&.label {
				white-space: pre-wrap;
				&.hide {
					display: none;
				}
			}
			&.shuffle {
				min-height: 52px;
				@media #{$responsive-laptop} {
					min-height: 35px;
				}
			}
		}
	}
}
@keyframes titileRibbonAnimation {
	// 0% {
	//  width: 50%;
	//  background-color: $primary-color;
	// }
	// 15% {
	//  width: 50%;
	//  background-color: $white;
	// }
	// 20% {
	//  width: 50%;
	//  background-color: $primary-color;
	// }
	// 25% {
	//  width: 50%;
	//  background-color: $white;
	// }
	// 30% {
	//  width: 50%;
	//  background-color: $primary-color;
	// }
	// 100% {
	//  width: 14px;
	//  background-color: $primary-color;
	// }

	// 0% {
	//  left: 0%;
	//  right: 100%;
	//  width: auto;
	//  background-color: $primary-color;
	// }
	// 20% {
	//  left: 0%;
	//  right: 0%;
	//  width: auto;
	//  background-color: $primary-color;
	// }
	// 30% {
	//  left: 0%;
	//  right: 0%;
	//  width: auto;
	//  background-color: $primary-color;
	// }
	// 50% {
	//  left: calc(100% - 14px);
	//  right: 0%;
	//  width: auto;
	//  background-color: $primary-color;
	// }
	// 80% {
	//  left: 0%;
	//  right: 0;
	//  width: auto;
	//  background-color: $primary-color;
	// }
	// 100% {
	//  left: 0%;
	//  right: calc(100% - 14px);
	//  width: auto;
	//  background-color: $primary-color;
	// }

	0% {
		width: 0;
		background-color: $primary-color;
	}

	40% {
		width: 14px;
		background-color: $primary-color;
	}

	45% {
		width: 14px;
		background-color: $white;
	}

	50% {
		width: 14px;
		background-color: $primary-color;
	}

	55% {
		width: 14px;
		background-color: $white;
	}

	60% {
		width: 14px;
		background-color: $primary-color;
	}

	65% {
		width: 14px;
		background-color: $white;
	}

	100% {
		width: 14px;
		background-color: $primary-color;
	}

}
@keyframes titileRibbonAnimation2 {
	0% {
		left: 100%;
		width: 0px;
		height: 55px;
	}
	20% {
		left: 25%;
		width: 50%;
		height: 55px;
	}
	40% {
		left: 0;
		width: 3.5rem;
		height: 55px;
	}
	60% {
		left: -10px;
		width: 3rem;
		height: 55px;
	}
	80% {
		left: 0px;
		width: 4rem;
		height: 55px;
	}
	100% {
		left: 0;
		width: 3.5rem;
		height: 55px;
	}
}
.section__content {
	opacity: 1;
	transition: 0.3s ease-in-out 0s;
}
@keyframes sectionTitlePrefixRender {
	0% {
		opacity: 0;
		transform: scaleY(0.4) translate(-10px, 10px);
		font-weight: 100;
	}
	5% {
		opacity: 1;
		transform: scaleY(1.4) translate(5px, -7px);
		font-weight: 500;
	}
	10% {
		opacity: 0;
		transform: scaleY(0.2) translate(-11px, -6px);
		font-weight: 100;
	}
	15% {
		opacity: 1;
		transform: scaleY(1.2) translate(6px, 8px);
		font-weight: 400;
	}
	20% {
		opacity: 0;
		transform: scaleY(1) translate(0px, 0px);
		font-weight: 400;
	}
	100% {
		opacity: 1;
		transform: scaleY(1) translate(0px, 0px);
		font-weight: 400;
	}
}


// SUBSECTIONS
.subsections {
	width: 100%;
	margin: auto;
	padding-left: 8rem;
	@include flex(flex-start, flex-start, row, !important);
	opacity: 1;


	position: relative;
	top: 140px;



	visibility: hidden;
	opacity: 0;
	overflow: hidden;
	height: 0;

	transition: 0.15s ease-in-out 0s;

	@media #{$responsive-laptop} {
		padding-left: 1rem;
	}

	@media #{$responsive-phone} {
		top: 120px;
	}
}
.subsection__container {
	@include flex(center, center, column);
	
	width: 100%;
	height: auto;
	position: relative;

	transition: 1s ease-in-out 0s;

	@media #{$responsive-laptop} {
		height: auto;
	}
}
.subsection {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(40px);
	z-index: 1;

	width: calc(100% - calc(8rem + 400px));
	
	// opacity: 0;
	transition: 0.15s ease-in-out;

	@media #{$responsive-phone} {
		width: calc(100% - 20px);
	}
	
	&.active {
		transform: translateY(0px);
		z-index: 2;
		.imageMask {
			max-height: 400px;
		}
		.imageGlass {
			max-height: 400px;
		}
		.subsection__content {
			max-height: 400px;
			@media #{$responsive-phone} {
				max-height: 800px;

				text-align: center;
			}
		}
		.subsection__media:before {
			opacity: 1;
			transition: 0.15s ease-in-out 0.3s;
			animation: subsection__media__bar--animation 2s ease-in-out infinite both 3s;
		}
	}
}
.subsection__wrapper {
	width: 100%;
	height: 100%;
	@include flex(flex-start, flex-start, column);

	@media #{$responsive-phone} {
		padding-right: 1rem;
	}

	&.responsive {
		@media #{$responsive-phone} {
			// @include flex(flex-start, flex-start, row);
		}
	}
}
.subsection__media {
	width: 100%;
	height: 220px;
	overflow: hidden;
	transition: 0.15s ease-in-out 0s;
	margin-bottom: 1rem;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0rem;
		left: 0;
		width: 0;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.05);
		opacity: 0;
		transition: 0.15s ease-in-out 0s;
		z-index: 3;
	}

	img {
		line-height: 0;
		display: block;
		border: none;
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: center center;
	}

	@media #{$responsive-laptop} {
		margin-right: 1rem;
		height: 120px;
	}
}
.imageMask {
	width: 100%;
	height: 100%;
	max-height: 0;
	overflow: hidden;
	transition: 0.15s ease-in-out;
}
.imageGlass {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	max-height: 0;
	overflow: hidden;
	animation: subsection__media__bar--animation 2s ease-in-out infinite both 5s;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		filter: blur(5px);
		animation: subsection__media__bar--animation 2s ease-in-out infinite both 5s reverse;
	}
}
.subsection__title {
	text-transform: uppercase;
	color: $white;
	font-size: 1.2rem;
	line-height: 1.4;
	font-weight: 600;
	margin: 0 0 1rem 0;
	text-align: left;

	@media #{$responsive-phone} {
		text-align: center;
	}
}
.subsection__content {
	text-align: left;
	overflow: hidden;
	height: auto;
	max-height: 0;
	width: 100%;
	transition: 0.15s ease-in-out;

	@media #{$responsive-phone} {
		text-align: center;
	}

	p {
		font-size: 1rem;
		line-height: 1.8;
		font-weight: normal;
		text-align: left;
		color: rgba(255, 255, 255, 0.85);
		@media #{$responsive-laptop} {
			font-size: 0.8rem;
		}
	}

	a {
		color: #ffcc00;
	}

	.list {
		max-height: 240px;
		columns: 220px 2;
		display: block;
		width: 100%;

		@media #{$responsive-laptop} {
			// columns: unset;
			// max-height: none;
			// @include flex(flex-start, flex-start, column);
		}

		&.trimmed {
			@media #{$responsive-tablet} {
				max-height: 120px;
				margin-bottom: 2rem;
				overflow: hidden;
			}
		}

		&__item, > li {
			margin: 0.5rem 0;
			line-height: 1.4;
			font-size: 0.8rem;
			@media #{$responsive-laptop} {
				font-size: 0.8rem;
			}
			@media #{$responsive-tablet} {
				margin: 0.1rem 0;
			}
		}
	}
}
.subsection__menu {
	width: 100%;
	max-width: 220px;
	position: absolute;
	right: 160px;
	// right: 270px;
	// top: 180px;
	opacity: 0;
	// top: 2rem;
	// top: 0rem;
	// top: 0;
	transition: 1s ease-in-out 0s;


	top: -175px;
	z-index: 12;

	@media #{$responsive-laptop} {
		top: -160px;
	}

	@media #{$responsive-tablet} {
		right: 1.2rem;
	}

	@media #{$responsive-phone} {
		right: 1rem;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		top: calc(100% + 10px);
		width: 1px;
		height: 100vh;
		background-color: rgba($white, 0.4);
	}

	ul {
		@include flex(flex-start, flex-end, column);
		margin: 0;
		padding: 0;
		list-style: none;
		
		li {
			width: auto;
			display: block;
			margin-bottom: 5px;
			position: relative;
			transition: 0.15s ease-in-out;
			
			a {
				display: block;
				text-align: right;
				color: $subsection_menu_textcolor;
				text-decoration: none;
				transition: 0.15s ease-in-out;
				font-size: 0.7rem;
				font-weight: 600;
				line-height: 1.4;
				text-transform: uppercase;
				width: auto;
				
				span {
					display: block;
					padding: 0.5rem 1.5rem;
					position: relative;
					z-index: 2;

					@media #{$responsive-tablet} {
						padding: 0;
						overflow: hidden;
						width: 2.5rem;
						height: 1.5rem;
						opacity: 0;
					}
				}

				&:before {
					transition: 0.15s ease-in-out;
					content: '';
					display: block;
				}
				&:before {
					width: 4px;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					background-color: $subsection_menmu_highlight;
					z-index: 1;
				}
			}
				
			&:hover {
				a {
					color: $subsection_menu_textcolor_active;
					&:before {
						background-color: $subsection_menu_textcolor_active;
					}
				}
			}
			
			&.active {
				a {
					color: $subsection_menu_textcolor_active;
					&:before {
						width: 100%;
						background-color: $subsection_menmu_highlight_active;
					}
				}
			}
		}
	}
}


// XSLIDER
.xslideshow {
	// width: 100%;
	// height: calc(100vh - 400px);
	width: calc(100vw - 0rem);
	height: calc(100vh - 0rem);
	position: absolute;
	top: 0rem;
	left: 0rem;
	z-index: 10;
	padidng: 0;
	// padding: 0 12rem 0 8rem;
}
.xslideshow__closeButton {
	position: absolute;
	z-index: 100;
	// top: -10px;
	top: 125px;
	right: -30px;
	// right: 255px;
	display: block;
	width: 30px;
	height: 30px;
	text-decoration: none;
	opacity: 0;

	transition: 0.3s ease-in-out 0s;

	@media #{$responsive-laptop} {
		// top: 2.7rem;
		top: 125px;
	}
	@media #{$responsive-tablet} {
		right: 0.3rem;
	}

	span {
		width: 30px;
		height: 4px;
		transition: 0.15s ease-in-out;
		position: absolute;
		top: 50%;
		margin-top: -2px;
		left: 50%;
		margin-left: -15px;
		background-color: $white;

		&:nth-child(1) {
			transform: scale(0.6) rotate(-45deg);
		}
		&:nth-child(2) {
			transform: scale(0.6) rotate(45deg);
		}
	}

	&:hover {
		transform: rotate(90deg);
		span {
			background-color: $primary-color;

			&:nth-child(1) {
				transform: scale(1) rotate(-45deg);
			}
			&:nth-child(2) {
				transform: scale(1) rotate(45deg);
			}
		}
	}
}
.xslideshow__paging {
	position: relative;
	z-index: 13;

	&:before {
		content: '';
		display: block;
		position: absolute;
		right: calc(12rem - 32px);
		// right: 270px;
		// top: 2rem;
		top: calc(125px);
		// width: 1px;
		// height: 0vh;

		width: 1px;
		height: calc(100vh - 125px);
		background-color: rgba($white, 0.4);

		transition: 1s ease-in-out 0s;
	}

	@media #{$responsive-laptop} {
		&:before {
			top: 5rem;
			height: calc(100vh - 5rem) !important;
		}
	}
	@media #{$responsive-tablet} {
		&:before {
			right: 1.2rem;
			top: 7.8rem;
			height: calc(100vh - 7.8rem) !important;
		}
	}
}
.xslideshow__pagingList {
	position: absolute;
	right: calc(12rem - 37px);
	// right: 265px;
	// top: 4rem;
	top: calc(125px + 4rem);
	z-index: 12;
	@include flex(flex-start, flex-start, column);
	list-style: none;
	margin: 0;
	padding: 0;
	opacity: 0;

	transition: 1s ease-in-out 0s;

	@media #{$responsive-laptop} {
		// top: 5rem;
		top: calc(125px + 4rem);
	}
	@media #{$responsive-tablet} {
		right: 0.88rem;
	}
}
.xslideshow__pagingItem {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	a {
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 2px solid $white;
		background-color: rgba($white, 1);
		transition: 0.15s ease-in-out;
	}

	&:hover {
		a {
			border: 2px solid $white;
			background-color: rgba($white, 0.5);
		}
	}

	&.active {
		a {
			border: 2px solid $primary-color;
			background-color: rgba($primary-color, 1);
		}
	}
}

.xslides {
	width: 100%;
	height: 100%;
	position: relative;

	@media #{$responsive-laptop} {
		// height: 400px;
	}

	.xslide {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		margin-top: 0rem;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;

		// transition: 1s ease-in-out 0s;

		// animation: xslideHide_main 1s ease-in-out both 1 0s;

		.xslide__wrapper {
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: relative;
			z-index: 2;

			@include flex(center, center, column);
			width: 100%;

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 10;
				top: 0;
				left: 150%;
				width: 300%;
				height: 100%;
				background-color: rgba($primary-color, 0.8);

				opacity: 1;
				transform: skew(20deg);

				// transition: 1s ease-in-out 0s;

				// animation: xslideHide_overlay 1s ease-in-out both 1 0s;
			}
		}
		.xslide__image {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
			top: 0;
			left: 0;
		}
		.xslide__imageShutter {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: -10%;
			z-index: 2;

			transform-origin: top left;
			transform: skew(-30deg) scaleX(1.5);

			@include flex(stretch, flex-start);

			span {
				width: 20%;
				height: 100%;
				// height: 0;
				flex-grow: 1;
				transform-origin: top left;
				transform: scaleX(0);
				transition: 0.30s ease-in-out 0s;

				&:nth-child(1) {
					background-color: rgba($white, 0.95);
				}

				&:nth-child(2) {
					background-color: rgba($white, 0.85);
					transition: 0.30s ease-in-out 0.15s;
				}

				&:nth-child(3) {
					background-color: rgba($white, 0.75);
					transition: 0.30s ease-in-out 0.30s;
				}

				&:nth-child(4) {
					background-color: rgba($white, 0.65);
					transition: 0.30s ease-in-out 0.45s;
				}

				&:nth-child(5) {
					background-color: rgba($white, 0.55);
					transition: 0.30s ease-in-out 0.6s;
				}
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			line-height: 0;
			transform: scale(1);
			position: relative;
			z-index: 1;
			// transition: 1s ease-in-out 0s;
			// animation: xslideHide_img 1s ease-in-out both 1 0s;
		}
		.xslide__context {
			position: absolute;
			z-index: 11;
			// top: 0;
			// left: 0;
			width: 100%;
			// height: 100%;
			height: auto;
			background-color: transparent;
			padding: 2rem 300px 2rem 10rem;
			opacity: 0;
			transform: scale(2);
			background-color: rgba($primary-color, 0.95);

			// transition: 1s ease-in-out 0s;

			@include flex(center, center, column);

			// animation: xslideHide_content 1s ease-in-out both 1 0s;

			@media #{$responsive-laptop} {
				padding: 2rem 220px 2rem 5rem;
			}
		}
		.xslide__title {
			margin-bottom: 0.5rem;
			h4 {
				font-size: 3rem;
				line-height: 1.4;
				font-weight: 500;
				margin: 0;
				text-transform: uppercase;
				@media #{$responsive-laptop} {
					font-size: 2rem;
				}
			}
			.portfolioLink {
				width: 100%;
				a {
					color: #ffcc00;
					text-decoration: none;
					outline: none;
					font-size: 1rem;
					line-height: 1.8;

					&:hover {
						color: #fff;
					}
				}
			}
		}
		.xslide__subtitle {
			font-size: 1.6rem;
			line-height: 1.4;
			font-weight: 100;
			margin: 0;
			@media #{$responsive-laptop} {
				font-size: 1.2rem;
			}

			.list {
				max-height: 240px;
				display: block;
				width: 100%;
				@include flex(flex-start, center);
				flex-wrap: wrap;
				&__item, > li {
					width: 50%;
					font-size: 1.6rem;
					line-height: 1.4;
					@media #{$responsive-laptop} {
						font-size: 1.2rem;
					}
				}
			}

			p {
				font-size: 1rem;
				line-height: 1.8;
				color: #fff;
			}
		}

		&.active {
			// z-index: 1;
			// opacity: 0;
			// visibility: hidden;
			// transition: none;

			animation: xslideShow_main 0.3s ease-in-out both 1 0.3s;

			img {
				// transform: scale(1);
				// transition: 10s ease-in-out 0s;
				// transition: none;

				// animation-name: xslideShow_img;
				// animation-duration: 10s;
				// animation-timing-function: ease-in-out;
				// animation-fill-mode: both;
				// animation-iteration-count: 1;
				// animation-delay: 0s;

				animation: xslideShow_img 10s ease-in both 1 0.5s;
			}

			&.show--context {

				.xslide__context {
					// opacity: 0;
					// transform: scale(2);
					// // transition: 1s ease-in-out 5s;
					// transition: none;

					animation: xslideShow_content 1s ease-in-out both 1 0.5s;
				}
				.xslide__wrapper {
					&:before {
						// left: 150%;
						// // transition: 1s ease-in-out 4s;
						// transition: none;

						// animation: xslideShow_overlay 1s ease-in-out both 1 0s;
					}
				}
				.xslide__imageShutter {
					span {
						transform: scaleX(1);
					}
				}
			}
		}

		&.exit--transition {
			animation: xslideHide_main 1s ease-in-out both 1 0s;
			// .xslide__context {
			//  opacity: 1;
			//  transform: scale(1);
			// }

			// img {
			//  transform: scale(1.2);
			// }

			// .xslide__wrapper {
			//  &:before {
			//    left: 150%;
			//  }
			// }
		}
	}
}

@keyframes xslideShow_border {
	0% {
		border: 1px solid rgba($white, 0);
	}
	10% {
		border: 1px solid rgba($white, 1);
	}
	20% {
		border: 1px solid rgba($white, 0);
	}
	30% {
		border: 1px solid rgba($white, 1);
	}
	40% {
		border: 1px solid rgba($white, 0);
	}
	50% {
		border: 1px solid rgba($white, 1);
	}
	100% {
		border: 1px solid rgba($white, 0);
	}
}

@keyframes xslideShow_main {
	0% {
		z-index: 1;
		opacity: 1;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}
	1% {
		z-index: 2;
		opacity: 1;
		height: 0;
		overflow: hidden;
		visibility: visible;
	}
	100% {
		z-index: 2;
		opacity: 1;
		height: 100%;
		overflow: hidden;
		visibility: visible;
	}
}
@keyframes xslideHide_main {
	0% {
		z-index: 1;
		opacity: 1;
		visibility: visible;
	}
	99% {
		z-index: 1;
		opacity: 0;
		visibility: visible;
	}
	100% {
		z-index: 1;
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes xslideShow_content {
	0% {
		opacity: 0;
		transform: scale(2);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes xslideHide_content {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(2);
	}
}

@keyframes xslideShow_img {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}

@keyframes xslideHide_img {
	0% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes xslideShow_overlay {
	0% {
		left: 150%;
	}
	100% {
		left: -50%;
	}
}
@keyframes xslideHide_overlay {
	0% {
		left: -50%;
	}
	100% {
		left: 150%;
	}
}

.xslideshow__play {
	position: absolute;
	top: calc(100% + 10px);
	right: 0;

	@media #{$responsive-phone} {
		display: none;
	}

	&Button {
		@include flex(flex-end, center);
		width: auto;
		min-height: 48px;
		background-color: rgba($primary-color, 0);

		transition: 0.15s ease-in-out;

		padding: 0.5rem 1rem 0.5rem 2rem;
		color: $white;
		font-size: 0.7rem;
		font-weight: 600;
		line-height: 1.4;
		text-transform: uppercase;
		text-decoration: none !important;

		position: relative;

		&:before {
			content: '';
			display: block;
			width: 0;
			min-height: 48px;
			height: 100%;
			transition: 0.3s ease-in-out 0s;
			background-color: $primary-color;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}

		&:hover {
			// background-color: rgba($primary-color, 1);

			&:before {
				width: 100%;
			}

			span.label {
				opacity: 1;
			}
		}
		
		img {
			order: 2;
			width: 30px;
			height: 30px;
			line-height: 0;
			display: block;
			position: relative;
			z-index: 2;
		}

		span.label {
			order: 1;
			margin-right: 0.5rem;
			text-align: right;
			color: $white;
			opacity: 0;
			transition: 0.3s ease-in-out 0s;
			position: relative;
			z-index: 2;
		}
	}
}


.normalLink {
	display: inline-block !important;
	background-color: $purple !important;
	color: #fff !important;
	padding: 0.5rem 1rem !important;
	font-size: 0.8rem !important;
	line-height: 1.4 !important;
	text-decoration: none !important;
	text-transform: uppercase !important;
	outline: none !important;

	transition: 0.15s ease-in-out;

	&:hover {
		background-color: darken($purple, 20%) !important;
	}
}


body.no-scroll {
	overflow: hidden !important;
}


// SANDBOX
.sandboxes {
	@include flex(flex-start, flex-start);
	flex-direction: column;
	flex-wrap: nowrap;

	.sandbox {
		// .sandbox__item {
		&__item {
			position: relative;
			width: calc(100% - 1rem);
			height: auto;
			margin: 0.5rem;

			@media #{$responsive-tablet} {
				width: 100%;
				margin: 0;
			}
		}
	}
}

// PROFILE
.profile__content {
	@include flex(flex-start, flex-start);
	flex-wrap: wrap;

	@media #{$responsive-tablet} {
		width: 100%;
		flex-wrap: nowrap;
		flex-direction: column;
		align-items: center;
	}
}
.profile__image {
	width: 120px;
	height: 120px;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 2rem;
	margin-top: 0.5rem;

	@media #{$responsive-tablet} {
		margin: 0 0 1rem 0;
	}

	img {
		width: 120px;
		height: 120px;
		object-fit: contain;
	}
}
.profile__text {
	width: calc(100% - 220px);
	@media #{$responsive-tablet} {
		width: 100%;
	}
}

.profile__textwrap {
	@media #{$responsive-tablet} {
		width: 100%;
		max-height: 9.6rem;
		overflow: hidden;
	}
}


.textExpand {
	background-color: $purple;
	color: #fff;
	padding: 0.5rem 1rem;
	font-size: 0.8rem;
	line-height: 1.4;
	text-decoration: none;
	text-transform: uppercase;
	outline: none;

	transition: 0.15s ease-in-out;

	display: none;

	&:hover {
		background-color: darken($purple, 20%);
	}
	@media #{$responsive-tablet} {
		display: inline-block;
	}
}

.text--large {
	font-size: 1.1rem !important;
}
.text--bold {
	font-weight: bold !important;
}

// CONTACT
.contact__info {
	@include flex(flex-start, center);
	flex-wrap: wrap;

	@media #{$responsive-tablet} {
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
	}
}
.contact__image {
	width: 120px;
	height: 120px;
	overflow: hidden;
	border-right: 1px solid rgba(255, 255, 255, 0.5);
	margin-right: 2rem;
	padding-right: 2rem;
	opacity: 0;

	@media #{$responsive-tablet} {
		border-right: none;
		margin-right: 0;
		padding-right: 0;

		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}

	img {
		width: 120px;
		height: 120px;
		object-fit: contain;
	}
}
.contact__content {
	width: calc(100% - 220px);

	@media #{$responsive-tablet} {
		width: calc(100%);
	}
}
.contact__socialmedia {
	width: 100%;
	@include flex(flex-start, stretch);
	flex-wrap: wrap;

	&__item {
		@include flex(center, center);
		margin-right: 2rem;
		opacity: 0;

		@media #{$responsive-tablet} {
			width: calc(33.33% - 2rem);
			margin: 1rem;
		}

		a {
			width: 100%;
			display: block;
			outline: none;
			border: none;
		}

		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			transition: 0.15s ease-in-out;

			@media #{$responsive-tablet} {
				width: 100%;
				min-height: 30px;
			}
		}

		&:hover {
			img {
				transform: scale(1.1);
				opacity: 0.5;
			}
		}
	}
}

.fp-completely {
	.contact__image {
		animation: animate__contact__social 1s ease-in-out both 1 2s;
	}
	.contact__socialmedia__item {
		
		@for $i from 1 through 10 {
			&:nth-of-type(#{$i}) {
				animation: animate__contact__social 1s ease-in-out both 1 #{3 + ($i * 0.2)}s;
			}
		}
	}
}

@keyframes animate__contact__social {
	0% {
		opacity: 0;
		transform: translateY(0px);
	}
	5% {
		opacity: 1;
		transform: translateY(0px);
	}
	10% {
		opacity: 0;
		transform: translateY(0px);
	}
	15% {
		opacity: 1;
		transform: translateY(0px);
	}
	20% {
		opacity: 0;
		transform: translateY(0px);
	}
	25% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

// SKILL SETS

.skillset {
	perspective: 2000;
}

.fp-completely {
	.skillset__item {
		overflow: hidden;
		img {
			transition: 0.25s ease-in-out;
		}
		@for $i from 1 through 10 {
			&:nth-of-type(#{$i}) {
				animation: animate__skillsets 1s ease-in-out both 1 #{2 + ($i * 0.2)}s;
				img {
					animation: animate__skillsets__img 0.5s ease-in-out both 1 #{2.2 + ($i * 0.2)}s;
				}
			}
		}

		&:hover {
			img {
				transition: 3s ease-in-out;
				transform: scale(1.2);
			}
		}
	}
}

@keyframes animate__skillsets {
	0% {
		opacity: 1;
		clip-path: circle(10% at center);
		background-color: rgba(255, 255, 255, 1);
		transform: scale(0) translateY(-80px) rotateX(270deg);
	}
	50% {
		opacity: 1;
		clip-path: circle(10% at center);
		background-color: rgba(255, 255, 255, 1);
		transform: scale(1) translateY(40px) rotateX(-45deg);
	}
	100% {
		opacity: 1;
		clip-path: circle(100% at center);
		background-color: rgba(255, 255, 255, 0.95);
		transform: scale(1) translateY(0px) rotateX(0deg);
	}
}

@keyframes animate__skillsets__img {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.hide-on-mobile {
	@media #{$responsive-phone} {
		display: none !important;
	}
}

@keyframes subsection__media__bar--animation {
	0% {
		left: -100%;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 100%;
	}
}