* ,
*:before ,
*:after {
	box-sizing: border-box;
}

*::selection {
	background-color: $tertiary-color;
	color: $white;
}


body {
	margin: 0;
	color: $text-color;
	font-family: $font-family;
	overflow-x: hidden;
	width: 100%;
}


#main_content {
	// opacity: 0;
	transition: opacity 300ms ease-out;
}

html.ready #main_content {
	// opacity: 1;
}

img {
	max-width: 100%;
}

body.body-no-scroll {
	overflow-y: hidden;
}