@import "../../utils/utils.scss";

.list {
  // @include flex(flex-start, flex-start, column);
  // flex-wrap: wrap;
  width: 100%;
  height: auto;

  margin: 0;
  padding: 0;
  list-style: none;

  column-count: 4;
  column-gap: 0.5rem;

  @media #{$responsive-phone} {
    column-count: 3;
  }

  &__item,
  > li {
    @include flex(flex-start, center);
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0.2rem 0.5rem 0.2rem 1rem;
    position: relative;
    cursor: default;
    break-inside: avoid-column;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: $primary-color;
      transition: 0.15s ease-in-out;
    }

    &:hover {
      &:before {
        left: 0.25rem;
      }
    }
  }

  &.list--triangle {
    &.list__item,
    & > li {
      &:before {
        width: 0;
        height: 0;
        border-radius: 0;
        background-color: transparent;

        border-top: 0.25rem solid transparent;
        border-bottom: 0.25rem solid transparent;
        border-left: 0.25rem solid green;
      }
    }
  }

  &.skillset {
    box-sizing: border-box;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    list-style: none;

    li:before {
      display: none;
    }

    .skillset__item {
      box-sizing: border-box;
      @include flex(stretch, stretch);
      // width: calc(20% - 0.5rem);
      width: 100%;
      height: 110px;
      margin: 0 0 0.5rem 0;
      padding: 0;
      overflow: hidden;

      background-color: rgba(255, 255, 255, 0.95);

      @media #{$responsive-phone} {
        // width: calc(33.33% - 0.5rem);
        width: 100%;
        height: 50px;
      }

      .skillset__wrapper {
        @include flex(stretch, stretch);
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.blockList {
  @include flex(flex-start, flex-start, column);
  flex-wrap: nowrap;
  width: 100%;
  height: auto;

  margin: 0;
  padding: 0;
  list-style: none;

  &__item,
  > li {
    @include flex(flex-start, center);
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0.2rem 0.5rem;
  }
}
