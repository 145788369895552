@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function determine-text-color($color) {
	@if (lightness($color) > 80) {
		@return $text-color;
	} @else {
		@return $text-color-white;
	}
}
